import preSchoolPoster from "../../../Images/NewsPictures/pre-school-dance-class.png";
import dancePartyShowdownImg from "../../../Images/NewsPictures/dance_party_showdown.jpeg";
import halloweenWorkshop from "../../../Images/NewsPictures/halloween_workshop.jpeg";
import chittyBangBang from "../../../Images/NewsPictures/chitty_bang_bang.jpeg";
import bakeAndShake from "../../../Images/NewsPictures/bake_and_shake.jpeg";
import beauSmith from "../../../Images/NewsPictures/beauSmith.jpeg";
import jaayC from "../../../Images/NewsPictures/jaayC.jpg";
import christmasMarket from "../../../Images/NewsPictures/christmas_market.jpg";
import thePolarExpress from "../../../Images/NewsPictures/the_polar_express.jpg";
import seaNoEvil from "../../../Images/NewsPictures/sea_no_evil.jpg";

const NewsPageArticles = [
  {
    id: 16,
    date: "09/02/2025",
    image: chittyBangBang,
    summary:
      "We are thrilled to announce that our amazingly talented student Isla Armitage will be performing in the professional production UK Tour of @chittyontourone in Sheffield.",
    summary2:
      "Isla is a Junior MT Associate and Management client and little pro when it comes to self tapes and auditions and this audition process has been no different.",
    summary3:
      "We are thrilled to see Isla land her first Stage credit and we are excited to keep working with her through this process.",
    summary4: "Well done Isla!",
  },
  {
    id: 15,
    title: "Sea No Evil",
    date: "28/10/2024",
    image: seaNoEvil,
  },
  {
    id: 14,
    title: "The Polar Express - Movie Night!",
    date: "28/10/2024",
    image: thePolarExpress,
  },
  {
    id: 13,
    title: "Christmas Market",
    date: "28/10/2024",
    image: christmasMarket,
  },
  {
    id: 12,
    title: "Congratulations Jaay!",
    date: "02/10/2022",
    summary:
      "We would like to say a bug farewell to our talented performer Jaay Cawthorn who has been accepted at EPA Academy of Performing Arts with a full scholarship on a three year Musical Theatre course. We wish you all the very best Jaay!",
    image: jaayC,
  },
  {
    id: 11,
    title: "Congratulations Beau Smith!",
    date: "02/10/2022",
    summary:
      "A huge congratulations to Beau Smith for successfully auditioning and gaining a place as a junior associate with the prestigious Royal Ballet. Beau has now commenced weekly training with them in Leeds along side his usual classes.",
    image: beauSmith,
  },
  {
    id: 10,
    title: "Bake 'n' Shake",
    date: "21/08/2022",
    image: bakeAndShake,
  },
  {
    id: 9,
    title: "Un Muted Show",
    date: "27/10/2021",
    summary: "There will be rehearsals on the following days:",
    summary2: "- Wednesday 27th 12-4pm",
    summary3: "- Friday 29th 12-4pm (costumes)",
    summary4: "- Thursday 4th 4 - 7:30pm (costumes)",
    summary5: "- Saturday 6th 10am - 4pm at the Theatre",
  },
  {
    id: 8,
    title: "Halloween",
    date: "18/10/2021",
    summary: "On Tuesday the 26th October, we have our Halloween Dance Day!",
    summary2:
      "The workshop is £20 for the full day, but book both this and our Dance Party Showdown for only £35.00! Everyone is welcome.",
    image: halloweenWorkshop,
  },
  {
    id: 7,
    title: "Dance Party Showdown",
    date: "18/10/2021",
    summary: "On Tuesday the 19th October, we have our Dance Party Showdown!",
    summary2:
      "The workshop is £20 for the full day, but book both this and our very spooky Halloween Dance Day for only £35.00! Everyone is welcome.",
    image: dancePartyShowdownImg,
  },
  {
    id: 6,
    title: "Pre-school Dance Classes",
    date: "10/05/2021",
    summary:
      "As of the 1st June 2021, we will be introducing pre-school dance classes on Tuesday evenings so that you do not have to wait for our weekend classes to get your little one's dancing and having fun.",
    summary2:
      "For more information check our Facebook page, or email us directly!",
    image: preSchoolPoster,
  },
  {
    id: 5,
    title: "WE ARE BACK!",
    date: "12/04/2021",
    summary:
      "Our studio doors reopen today and we are so excited to welcome both familiar and new faces alike! We are ENROLLING NOW! Get in touch to join our fun and friendly environment.",
    summary2:
      "We appreciate it has been a tough year, and therefore wanted to extend a huge thank you to all of our amazing parents and students for the support we have received during the recent months. It really does mean the world to us and we cannot wait to see you all again!",
  },
  {
    id: 4,
    title: "Studio Update.",
    date: "02/03/2021",
    summary:
      "We are looking forward to seeing you all back in the studio soon! The new term commences on 1st April and the school is due to re-open on Monday 12th April. The new timetable will be issued shortly.",
    summary2:
      "The School will be closed on Monday 5th April, Monday 3rd May and Monday 31st May 2021.",
    summary3:
      "Students are invited to join Shane @ Z-Ten media for a fantastic dance photo shoot on Sunday 18th April at Manor Farm, Oldcotes, Blyth. Please check out his website for further information and to reserve your place!",
  },
  {
    id: 3,
    title: "Latest COVID announcement.",
    date: "26/02/2021",
    summary:
      "On 22nd February, the UK Government announced a four stage roadmap out of the current lockdown in England. We are now awaiting for clarification what this means for us and how we will proceed. We will share updates with you as soon as we can. The health and well being of teachers, students and staff is a top priority for the school.",
  },
  {
    id: 2,
    title: "Freebie Friday Classes",
    date: "26/02/2021",
    summary:
      "We would like to invite our dedicated Zoom students to join our FREE classes this Friday!",
    summary2:
      "Theatre Jazz - with teacher Wednesday Houghton 5:00 - 6:00pm (over aged 10 years) an energetic hour of high kicks, turns and jazz hands! Log onto Pink Code.",
    summary3:
      "Tell a Fairy Tale - with teacher Stacey Rea 5:00 - 5:45PM (10 years & under) Children can dress up and enjoy a magical interactive story time! Log onto Green Code.",
    summary4:
      "Week 8 Challenge - Right now, in the age of COVID-19's self-distancing, there's never been a better time, and bigger need, for the benefits of nature. This weeks challenge is to send us 3 photos of 3 different dance poses in 3 different places anywhere outdoors! Being outdoors in nature can be healing and beneficial for the body and mind...have some fun!",
  },
  {
    id: 1,
    title: "Welcome to our new website!",
    date: "22/02/2021",
    summary:
      "After years of building our great local reputation through word of mouth, our website is finally here for all updates on the school and our classes. We would also like to thank Shane Rumsey for his fantastic photography skills over at Z-Ten Media... feel free to visit at www.zten.co.uk!",
  },
];

export default NewsPageArticles;

import wendyImage from "../../../Images/StaffPictures/wendyPicNew.jpg";
import amandaPic from "../../../Images/StaffPictures/amandaImage.jpg";
import SharonPic from "../../../Images/StaffPictures/SharonPicNew.jpg";
import LynettePic from "../../../Images/StaffPictures/lynettePicNew.jpg";
import BethanieImg from "../../../Images/StaffPictures/Bethanie.jpeg";
import danPonickeImg from "../../../Images/StaffPictures/danImageNew.jpg";
import stacyRea from "../../../Images/StaffPictures/stacyRea.jpeg";
import amyRea from "../../../Images/StaffPictures/amyRea.jpeg";
import angiePic from "../../../Images/StaffPictures/angiePicNew.jpg";
import jasminePic from "../../../Images/StaffPictures/jasminePicNew.jpg";
import fionaPic from "../../../Images/StaffPictures/fionaPicNew.jpg";
import anatoliePic from "../../../Images/StaffPictures/anatolieImage.jpg";
import georgiaIrvinePic from "../../../Images/StaffPictures/georgiaIrvine.jpg";

const staffDetails = [
  {
    id: 1,
    firstName: "Wendy ",
    surname: "De Burnay-Bastos",
    summary1:
      "Wendy took her first ballet class at the age of 8. By age 9, she was accepted as a Junior Associate with the Royal Ballet at Sadlers Wells Theatre, London. It was here that her career in dancing truly took hold, soon training with Louise Brown OBE and the Young London Ballet Company. She continued her training at the Arts Educational School, Tring Park and then Laine Theatre Arts, Epsom.",
    summary2:
      "Following a successful career in dancing overseas, Wendy set up De Burnays Dance Centre in order to fuel her passion for developing the next generation of talent in dance. With an extensive experience in teaching, coaching, performing, choreographing and directing spanning more than 27 years, Wendy prides herself on teaching to the highest standard, and has clear passion and vision for every student she works with. Wendy is also an approved Royal Academy of Dance CBTS mentor, working with student teachers to assist and enhance their knowledge and understanding of the practice of dance teaching.",
    image: wendyImage,
  },
  {
    id: 2,
    firstName: "Amanda ",
    surname: "Thompson",
    summary1:
      "Dancing was always going to be in Amanda’s life. After starting at the age of 2, Amanda had found her passion in life and by the age of 16 she had become a regular festival competitor, winning many championships, passing all her ISTD and RAD exams and performed professionally in many shows. After finishing school she went on to train in professional dance at SLP College for three years, completing her teacher training qualifications and furthering her knowledge in musical theatre, jazz, tap, ballet, contemporary, singing and acting. On completing her diploma and teaching exams with distinctions she went on to work professionally on cruise ships and various professional contracts in theatres and appearing on TV in England and abroad.",
    summary2:
      "After returning to England, she wanted to pursue a career in teaching and choreography; she has since choreographed many shows and festival routines and continues to teach in many schools around the Yorkshire and Nottinghamshire areas offering summer schools and after-school clubs. Amanda has been a central part of De Burnays Dance Centre for over 20 years, and is firmly part of the furniture!",
    image: amandaPic,
  },
  {
    id: 3,
    firstName: "Bethanie ",
    surname: "Houghton",
    summary1:
      "Bethanie graduated from London Studio Centre in 2017 with a 1st Class Honours Degree in Theatre Dance, awarded with the Lia Williams Award for Best Performance in Musical Theatre. During her training, she was lucky enough to perform in multiple shows including Bad Girls: The Musical as Yvonne Atkins. Beth trained in Estill Voice Technique at University and has since taken regular 1-2-1 lessons and workshops with one of the West End and British Recording Industry’s leading vocal coaches, Dane Chalfin in accent method.",
    summary2:
      " Since graduating, Bethanie regularly performed in 5* hotels and bars in London. In 2019, she joined the band ‘On Shuffle’ as their lead singer and has since travelled the world with Celebrity Cruises. During the pandemic, Bethanie returned back to the UK and opened her own performing and teaching business, Bethanie Houghton Music. This has led to many wonderful performing opportunities at weddings, functions and events and most recently becoming a vocal coach at multiple academies in Nottinghamshire & South Yorkshire. Beth feels very lucky to still be professionally performing and teaching and is looking forward to sharing her knowledge with the next generation of performers at Deburnay’s Dance Centre! ",
    image: BethanieImg,
  },
  {
    id: 4,
    firstName: "Jasmine ",
    surname: "Melbourne",
    summary1:
      "With 15 years experience training at the De Burnays Dance Centre in subjects including classical ballet, modern, tap, street, contemporary and musical theatre, Jasmine is a talented all-round teacher. Jasmine is also a previous associate with Tap Attack for 6 years, and was also a member of the Advanced Training programme at the Northern Ballet Centre, the Northern Ballet Senior Associate and the Yorkshire Ballet Scholarship Centre for 3 years. Her certified qualifications include the Royal Academy of Dance Certificate in ballet teaching studies (2016-2019) and ISTD Diploma in Dance Education with Distinction for Tap and Modern Theatre (2018-2019).",
    summary2:
      "If that isn't enough, Jasmine is also aiming to become an examiner and is currently studying for her Licentiate Tap qualification with ISTD. Overall, this makes her a fantastic young achiever with an amazing ability and passion for dance and developing those she teaches.",
    image: jasminePic,
  },
  {
    id: 5,
    firstName: "Dan ",
    surname: "Ponicke",
    summary1:
      "Daniel is a former De Burnays student who attended Yorkshire Ballet Scholarship Associate programme and performed with the English National Ballet Summer Schools. Dan successfully gained a place with Northern Ballet School, where he trained 3 years and graduated in the Classical Focus performing Hillarion in Giselle 2012. After graduating Daniel performed all over the world, joining Princess Cruises in LA for the premier of their Japanese cruise season and the incredible Norwegian Cruise Line Company. Daniel then accepted a world cruise contract with PEEL entertainment working on Fred Olsens Balmoral, before joining Celebrity Cruises and the Infinity Team in 2014.",

    summary2:
      'When Dan came home from Celebrity he decided to take a different approach to his Dancing: teaching and choreography. As he has grown to be part of the team, he appreciates the family ethos of De Burnays and how important it is to train the future dancers of tomorrow. In his own words, Daniels favourite part of working at De Burnays is "being proud to teach such incredible children"',
    image: danPonickeImg,
  },
  {
    id: 6,
    firstName: "Stacy ",
    surname: "Rea",
    summary1:
      "Stacey is a singer and dancer, who is currently working at Disneyland Paris as Face Character and Parade Dancer. She is an IDTA qualified dance teacher, has a 3 Year Trinity Diploma and 1 Year Foundation Course Diploma from SLP College. She also has LCMA, LAMDA and ISTD exam certificates.",
    summary2:
      "Since leaving college, Stacey has worked in numerous productions on land and on cruise ships. Notably, performing in the New Zealand tour of Annie The Musical, as Annette/Ensemble and working for Headliners Theatre Company and Grayboy Entertainment, on their highly-reviewed P&O cruise ships, as Lead Vocalist. Stacey has a upbeat personality and loves working with children and teenagers, helping them to improve their confidence on stage and learn the importance of performance, combined with their technique.",
    image: stacyRea,
  },
  {
    id: 7,
    firstName: "Amy ",
    surname: "Rea",
    summary1:
      "Amy is a professional singer and dancer. She graduated from SLP College with a Level 6 Diploma in Musical Theatre and has worked in this industry in many different countries. Upon graduation Amy moved to France to work at Disneyland Paris as a professional dancer, working on the parade ‘Magic Everywhere’ and being the opening cast for the newest parade ‘Stars on Parade’.",
    summary2:
      "Amy has learnt how important it is to have passion and love for the industry and hopes to share this passion to children in learning to dance and sing. She also understands how important technique is in both singing and dancing, in order to not cause injury, and aims to teach a grounding foundation in technique in all her classes. Amy is bubbly and fun and creates lasting memories and a passion to perform in all her classes.",
    image: amyRea,
  },
  {
    id: 8,
    firstName: "Anatoly ",
    surname: "Buryak",
    summary1:
      "Anatoly born in Moscow, began Ballroom and Latin dancing at the age of 7.  By his early 20’s he was representing Russia with his Formation team in the IDSF (International Dance Sport Federation) World Championships in Germany, winning the Gold medal. From there he and his team were finalist in many IDSF world and European Championships, including IDSF National Cup and German Open, winning the Bronze medal.",
    summary2:
      "After an extensive career on some of the Worlds biggest stages, Anatoly joined Princess Cruises in 2004, giving him the opportunity to work with some of the best Broadway Musical  choreographers, singers and dancers for the next 6 years, before finally moving to England. If you’re a bit more serious about your dancing and want to learn the elegant, refined style that comes from solid foundation technique, Anatoly is the teacher you want. His deep and accomplished background in Ballroom and Latin plus his honest, yet funny teaching style will have you improving fast.",
    image: anatoliePic,
  },
  {
    id: 9,
    firstName: "Georgia ",
    surname: "Irvine",
    summary1:
      "Georgia has extensive experience in both dance performance and choreography. She trained to advanced level in R.A.D. Classical Ballet & I.S.T.D Modern & Tap. Georgia completed her training at Dance City, Newcastle which was heavily contemporary based and graduated with a first class BA Honours degree.",
    summary2:
      "Georgia has worked alongside many renowned choreographers from across the UK such as Sarah Blanc, Patrick Ziza, Patricia Okenwa, Richard Chappell and Renaud Wiser. Georgia also performed in a professional events including ‘The Late Shows’ and ‘DANE Arts’  scratch night of which she performed her own Choreography ‘Faded’.",
    image: georgiaIrvinePic,
  },
  {
    id: 10,
    firstName: "Sharon ",
    surname: "Wilkinson",
    summary1:
      "Sharon (or lovingly known as 'Nanny Sharon') has extensive experience working with youngsters, having worked for 20 years with children aged 2 up to 18. Like many of our members of staff, Sharon has also completed various certifications and training programmes: The National Fostering Standards, Promoting Positive Behaviour, Play Therapy, Therapeutic Parenting, Building Self Esteem and Pediatric First Aid are all things that make her amazing for students and teachers alike!",
    summary2:
      "In her spare time, Sharon loves to help out in her local community outside of work, baking amazing cakes and cooking/delivering meals to elders.",
    image: SharonPic,
  },
  {
    id: 11,
    firstName: "Angie ",
    surname: "Sullivan",
    summary1:
      "Angie is a former De Burnays dance mum, who's daughter successfully graduated from performing arts college and now runs her own dance school in New Zealand. As such, this has given Angie such a great insight into the world of dance.",
    summary2:
      "Since then, Angie has been part of the De Burnays team and has been with us for 11 years, and is always here to help in whichever way she can. From helping out in the office to organising events, Angie is a committed and loyal staff member and is passionate about De Burnays Dance Centre.",
    image: angiePic,
  },
  {
    id: 12,
    firstName: "Lynette ",
    surname: "Smith",
    summary1:
      "With qualifications in computer literacy, IT and business calculations, Lynette is our dance festival and examination administrator and puts her 25 years of experience to work. Whether it be invoicing and filing, monitoring emails or covering phone calls, Lynette has organisational skills second to none... Without her, we'd be lost within hours!",
    image: LynettePic,
  },
  {
    id: 13,
    firstName: "Fiona ",
    surname: "White",
    summary1:
      "Fiona White is a long-standing De Burnays dance mum, with all of her children joining the school at some point and her daughter joining the prestigious Bird College. It's fair to say she has lived and breathed the dance world! Fiona has her first aid certificate and is a registered chaperone with NCC. Nicknamed 'Manuel' due to her phenomenal ability to multi task, Fiona is our designated organiser at our annual productions and dance festivals. Fiona also runs the Dance Shop located on the ground floor and is a certified pointe shoe fitter through Grishko.",
    image: fionaPic,
  },
];

export default staffDetails;
